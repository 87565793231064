const SEPARATOR = '//**//';
const CACHE_INTERVAL = 0.2 * 60 * 1000 * 4;
const CACHE_NAMESPACE = 'OA_req_cached_'

// Useful when creating a new user or similar, where everything starts from scratch.
// Also useful when we need to clear eveything up
function clearRequestCache() {
  for (let i = 0, len = localStorage.length; i < len; ++i) {
    if (localStorage.key(i)?.startsWith(CACHE_NAMESPACE)) {
      localStorage.removeItem(localStorage.key(i)!);
    }
    console.log(localStorage.key(i));
  }
}

function store(key: string, value: string) {
  const finalValue = `${value}${SEPARATOR}${Date.now().toString()}`;
  localStorage.setItem(CACHE_NAMESPACE+ key, finalValue);
}

interface IsValidResponse {
  isValid: boolean;
  value?: string;
}

// Returns true if an object has been stored using the store method
// and have yet not expired
function isValid(key: string): IsValidResponse {
  const value = localStorage.getItem(CACHE_NAMESPACE + key);
  if (value === null) {
    return {
      isValid: false,
    };
  }
  const values = value.split(SEPARATOR);
  const timestamp = Number(values[1]);
  if (Number.isNaN(timestamp)) {
    return {
      isValid: false,
    };
  }
  const date = new Date(timestamp);
  if (date.toString() === 'Invalid Date') {
    return {
      isValid: false,
    };
  }
  if (Date.now() - date.getTime() < CACHE_INTERVAL) {
    return {
      isValid: true,
      value: values[0],
    };
  }
  localStorage.removeItem(CACHE_NAMESPACE + key);
  return {
    isValid: false,
  };
}

export default {
  store,
  isValid,
  clearRequestCache
};
