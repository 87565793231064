import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import cache from './utils';

function responseHandler(response: AxiosResponse<any>): AxiosResponse<any> {
  // eslint-disable-next-line no-constant-condition
  if (response.config.method === 'GET' || response.config.method === 'get') {
    if (response.config.url) {
      cache.store(response.config.url, JSON.stringify(response.data));
    }
  }
  return response;
}

function errorHandler(error: any) {
  if (error.headers.cached === true) {
    return Promise.resolve(error);
  }
  return Promise.reject(error);
}

function requestHandler(request: AxiosRequestConfig) {
  if (request.method === 'GET' || request.method === 'get') {
    const checkIsValidResponse = cache.isValid(request.url || '');

    console.log(checkIsValidResponse);
    if (checkIsValidResponse.isValid) {
      request.headers.cached = true;
      request.data = JSON.parse(checkIsValidResponse.value || '{}');
      return Promise.reject(request);
    }
  }
  return request;
}

export const makeCachedRequest = async (url: string, method: string, body?: any, headers?: any) => {
  const options: any = {
    method,
    url,
  };

  if (body) {
    options.data = body;
  }

  if (headers) {
    options.headers = headers;
  }
  const client = axios.create(options);
  client.interceptors.request.use(request => requestHandler(request));
  client.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error)
  );
  try {
    return await client(options);
  } catch (error: any) {
    if (error.message.includes('401')) {
      window.location.replace('/');
    }

    throw error;
  }
};

export const { clearRequestCache } = cache;
